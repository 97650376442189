<template>
  <v-chart class="chart" :option="option"  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { getHeatingPower } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
var data = [15, 40, 20, 52, 27]
export default {
  components: {},
  props: ["data"],
  data() {
    return {
      option: {
        tooltip: {},
        timer:0,
        grid: {
          x: 30,
          y: 20,
          x2: 0,
          y2: 30,
        },
        xAxis: {
          type:'category',
          // data: ["绍兴", "绍兴", "绍兴", "绍兴", "绍兴"],
          axisLine: {
            lineStyle: {
              color: "#103e4f",
              fontSize: "16",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: "#a3fffe",
          },
        },
        yAxis: {
          max: 999,
          type: "value",
          interval: 300,
          axisTick: false,
          splitLine: {
            lineStyle: {
              color: "#103e4f",
            },
          },
          axisLabel: {
            color: "#a3fffe",
          },
        },
        series: [
          {
            name: "人流来源省份情况",
            data: data,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(132, 178, 197, 0.3)",
              borderRadius: [15, 15, 15, 15],
            },
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              },
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgb(0, 255, 255)",
                  },
                  {
                    offset: 1,
                    color: "rgb(0, 175, 248)",
                  },
                ],
                globalCoord: false,
              },
              borderRadius: [15, 15, 15, 15],
            },
            barMaxWidth: 17,
            // barCategoryGap: "20",
          },
        ],
      },
    };
  },
  methods: {
    getData() {
      getHeatingPower().then((res) => {
        this.option.series[0].data = res.data.data.province_count.data.slice(0, 8).map((o) => {
          return { name: o.data_name, value: o.data_num }
        });
        this.option.xAxis.data = res.data.data.province_count.data.slice(0, 8).map((o) => {
          return { value: o.data_name };
        })
        return
      });
    }
  },
  mounted() {
    this.getData();
    this.timer = setInterval(this.getData, 6000)
  },
};
</script>

<style scoped></style>
